import React from 'react';
import './App.css';
import reactLogo from './react.svg';
import htmlLogo from './html.png';
// import jerichoCyberLogo from './jericho-cyber-logo-color.png'
import metcalfesLogo from "./metcalfes-logo-white.png"



function App() {
  return (
    <div className="App">
      <header className="Header">
            <img className="logo" src={metcalfesLogo} alt="Alex Metcalfe" />
      </header>
      <div className="Body">
        <h1 className="animateTop">COMING SOON</h1>
        <p>IN 2022</p>
      </div>
      <footer className="Footer">
        {/* &#169;&nbsp;{new Date().getFullYear()} */}
        {/* <img src={jerichoCyberLogo} className="Logo" alt="Jericho Cyber Logo" /> */}
        <img src={reactLogo} className="App-logo" alt="React Logo" />
        <img src={htmlLogo} className="Logo" alt="HTML 5 Logo" />
      </footer>
    </div>
  );
}

export default App;
